<!--  Input que acepta solo texto y lo convierte en mayusculas-->
<label *ngIf="type == 'onlyUpperCase' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'onlyUpperCase'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       fieldSize="small"
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="validateAlphanumeric($event)"
       (input)="valueUpperReturn()">

<!--  Input que acepta todo el texto que se ingresa -->
<label *ngIf="type == 'all' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'all'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (input)="valueReturn()">


<!--  Input que acepta cantidades confraccion -->
<label *ngIf="type == 'double' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'double'"
       [ngClass]="['input-rectangle', class]"
       #inputDouble
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="onlyFloat($event,inputDouble);"
       (input)="valueReturn()">


<!--  Input que acepta numeros enteros-->
<label *ngIf="type == 'integer' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'integer'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="onlyNumeric($event)"
       (input)="valueReturn()">


<!--  Input que acepta numeros y letras-->
<label *ngIf="type == 'alphanumeric' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'alphanumeric'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="validateAlphanumeric($event)"
       (input)="valueReturn()">

<!--  Input que acepta numeros y letras-->
<label *ngIf="type == 'nospace' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'nospace'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="validateAlphanumericnospace($event)"
       (input)="valueReturn()">

<!--  Input que acepta solo letras-->
<label *ngIf="type == 'onlyLetters' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'onlyLetters'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="text"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="validateOnlyLetters($event)"
       (input)="valueReturn()">

<!--  Input que acepta y da formato de fecha-->
<label *ngIf="type == 'dateinput' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'dateinput'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="date"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       (keydown)="false"
       [min]="minDate"
       [max]="maxDate"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (keypress)="onlyNumeric($event)"
       (input)="valueReturn()">


<!--  Input que acepta todo el texto que se ingresa -->
<label *ngIf="type == 'password' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'password'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="password"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (input)="valueReturn()">

<!--  Input que acepta todo el texto que se ingresa -->
<label *ngIf="type == 'email' && label != null" class="label" style="justify-content: normal;">
  {{label}}<span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<input *ngIf="type == 'email'"
       [ngClass]="['input-rectangle', class]"
       [(ngModel)]="value"
       type="email"
       nbInput
       [ngStyle]="{'width': fitContent== true ? 'fit-content':'100%' }"
       fieldSize="small"
       [maxLength]="maxLength"
       [minLength]="minLength"
       [style.textAlign]="textAlign"
       [placeholder]="placeholder"
       [required]="required"
       [readOnly]="readonly"
       [disabled]="disabled"
       (input)="valueReturn()">
