<div id="large-header" class="large-header">
  <div class="demo">
    <div class="login">
      <div class="alert alert-danger" [hidden]="!error">
        {{error}}
      </div>
      <div class="login__check">
      </div>
      <div class="login__form" style="top: 10%!important;">
        <form name="form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()" style="position: relative">
          <div style="padding:0 2rem 0;">
            <p class="login__signup">{{message1}}</p>
          </div>
          <div class="login__row" [ngClass]="{'is-invalid':form.get('email').touched && form.get('email').invalid}" >
            <i>
              <svg class="login__icon name svg-icon" viewBox="0 0 20 20">
                <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8"/>
              </svg>
            </i>
            <input type="text" name="email" class="login__input email" formControlName="email" placeholder="Correo electr&oacute;nico" value="user@email.com"/>
            <div *ngIf="form.get('email').touched && form.get('email').invalid"  class="invalid-feedback">
              <div *ngIf="form.get('email').errors.required">El campo correo electr&oacute;nico es requerido</div>
              <div *ngIf="form.get('email').errors.email">El correo electr&oacute;nico debe ser un formato valido</div>
            </div>
          </div>
          <br/>
          <div *ngIf="isSubmited" style="padding:0 2rem 0;">
            <p class="login__message">{{message2}}</p>
          </div>
          <div>
            <button *ngIf="!isSubmited" type="submit" tabindex="3"
                    class="login__submit" [ngClass]=""
                    [disabled]="!form.valid">Enviar
            </button>
          </div>
          <div>
            <button *ngIf="!isSubmited" type="submit" tabindex="3"
                    class="login__back" [ngClass]=""
                    [routerLink]="['/login']">Regresar
            </button>
          </div>
          <div *ngIf="isSubmited" class="a loading" style="--n: 5;">
            <div class="dot" style="--i: 0;"></div>
            <div class="dot" style="--i: 1;"></div>
            <div class="dot" style="--i: 2;"></div>
            <div class="dot" style="--i: 3;"></div>
            <div class="dot" style="--i: 4;"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
