<nb-card [ngStyle]="{'height': 'calc(' + heightDiv + ' 5vh )'}">
  <nb-card-body>
    <nb-tabset (changeTab)="fixSize()">
      <nb-tab *ngFor="let item of tableDataArray; let a = index" tabTitle="{{item.titletab}}">
        <table-index style="width: 100%;" [heightDiv]="heightDiv" [tableData]="item.tableData"
                     (valueEditData)="valueEditData($event, item.titletab)" [borderCard]="false"
                     (cellClicked)="cellClicked($event, item.titletab)"
                     (optionsTableClicked)="optionsTableClicked($event, item.titletab)"></table-index>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
