<button  [ngClass]="{'btn-primary' : tipeclass == 'primary',
         'btn-success' : tipeclass == 'success',
         'btn-info' : tipeclass == 'info',
         'btn-warning' : tipeclass == 'warning',
         'btn-danger' : tipeclass == 'danger'}" [type]="tipetype"
         [disabled]="tipedisabled"
         class="btn btn-icon btn-sm animate__animated animate__backInRight"
         (click)="processOption(true)" style="margin-right: 0.3rem;margin-left: 0.3rem">
  <i [ngClass]="tipeicon" aria-hidden="true"></i>
  <span>&nbsp;{{tipetitle}}</span></button>
