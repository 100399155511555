import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';


@Component({
    selector: 'ngx-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {
    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            //Validators.email,
        ]),
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(10),
        ]),
    });
    isSubmited: boolean = false;
    public error = null;
    errorMessage: string;

    constructor(
        private _authService: AuthService,
        private router: Router,
    ) {
      //Se forza porque si no se quedaba en cache datos de sesion anterior
      if (localStorage.getItem('refreshed') === null) {
        localStorage['refreshed'] = true;
        window.location.reload();
      } else {
        localStorage.removeItem('refreshed');
      }
    }
  /* tslint:disable:no-console */
    public onSubmit() {
        this.isSubmited = true;
        const body = {email: this.form.value.email.toLowerCase(), password: this.form.value.password};
        this._authService.login(body).pipe(
            tap((tokens: any) => this._authService.doLoginUser(this.form.value.email, tokens)),
            mapTo(true),
            catchError(error => {
                this.handleError(error);
                return of(false);
            })).subscribe(
            res => {
                try {
                  if (res) {
                    setTimeout(() => {
                      const dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
                      if (dataUser.tipo != "checkin") {
                        this.router.navigate(['/pages']).then(() => {
                          this.isSubmited = false;
                        });
                      } else {
                        this.router.navigate(['/asistencia/asistencia']).then(() => {
                          this.isSubmited = false;
                        });
                      }
                    }, 500);
                  }
                  else {
                    this.isSubmited = false;
                  }
                } catch (e) {
                  //console.log('edit_route fail redirect ', e);
                }
            },
            err => {
                this.isSubmited = false;
                //console.log(err);
            },
        );
    }
  /* tslint:enable:no-console */

    handleError(error) {
      switch (error.status) {

        case 401:
          this.setErrorMessage('No estás autenticado. Por favor, inicia sesión.');
          break;
        case 403:
          this.setErrorMessage('No tienes permiso para acceder a este recurso.');
          break;
        case 404:
          this.setErrorMessage('El recurso solicitado no se encontró.');
          break;
        case 500:
          this.setErrorMessage('Ocurrió un error en el servidor. Por favor, inténtalo de nuevo más tarde.');
          break;
        default:
          this.error = error.error.error;
          this.setErrorMessage('Ocurrió un error: ' + error.error.error);
      }
    }
    private setErrorMessage(message: string) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = null;
      }, 10000); // Ocultar el mensaje después de 5 segundos
    }
    ngOnInit(){

    }
    ngAfterViewInit(): void {
    (function () {

      let width, height, largeHeader;
      // Main
      initHeader();

      function initHeader() {
        width = window.innerWidth;
        height = window.innerHeight;
        if (height < 640) {
          height = 640;
        }
        largeHeader = document.getElementById('large-header');
        largeHeader.style.height = height + 'px';
      }
    })();
    this.form.reset();
  }
}
