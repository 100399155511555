import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class PuestoService {
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Request-Method':'POST,GET,OPTIONS',
            'Access-Control-Request-Headers':'Content-Type, Authorization'
        })
    };
    private dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
    private uuid = this.dataUser.uuidCompany;
    private currentUser = this.dataUser.name;
    constructor(
        private http: HttpClient
    ) {}

    createWorkStation(data: any): Observable<any> {
        let url = `${environment.api.CatalogsApis}/${this.uuid}/workstation`;
        return this.http.post(url, data, this.httpOptions);
    }
    updateWorkStation(data,register): Observable<any> {
        let url = `${environment.api.CatalogsApis}/${this.uuid}/workstation/`;
        return this.http.put(url+register,data);
    }
    deleteWorkStation(uuid): Observable<any> {
        let url = `${environment.api.CatalogsApis}/${this.uuid}/workstation/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }
    getWorkStation() {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/${this.uuid}/workstation`;
        shareGet = this.http.get(url);
        return shareGet;
    }
}
