import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class CompanyService {
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Method': 'POST,GET,OPTIONS',
            'Access-Control-Request-Headers': 'Content-Type, Authorization'
        })
    };
    private dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
    private uuid = this.dataUser.uuidCompany;
    private currentUser = this.dataUser.name;

    constructor(
        private http: HttpClient
    ) {
    }

    createCompany(data: any): Observable<any> {
        let url = `${environment.api.CatalogsApis}/company`;
        return this.http.post(url, data, this.httpOptions);
    }

    createCompanySubs(data: any, uuid: any): Observable<any> {
        let url = `${environment.api.CatalogsApis}/company/${uuid}`;
        return this.http.post(url, data, this.httpOptions);
    }

    updateCompany(data, register): Observable<any> {
        let url = `${environment.api.CatalogsApis}/company/${register}`;
        return this.http.put(url, data);
    }

    getCompany() {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/company`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    getCompanySubsSelect(uuid) {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/company/subs-select/${uuid}`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    getCompanyUUID(uuid) {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/company/${uuid}`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    deleteCompany(uuid): Observable<any> {
        let url = `${environment.api.CatalogsApis}/company/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }
}
