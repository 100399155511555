import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule, NbDatepickerModule, NbInputModule, NbCardModule, NbAlertModule, NbTooltipModule, NbTabsetModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ButtonTextComponent } from './components/button-text/button-text.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import {ChangePageComponent} from "./components/change-page/change-page.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {ChangePageService} from "./components/change-page/change-page.service";
import {LoadingService} from "./components/loading/loading.service";
import { CalendarLabelComponent } from './components/calendar-label/calendar-label.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { LayoutDirectionSwitcherComponent } from './components/layout-direction-switcher/layout-direction-switcher.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { SelectComponent } from './components/select/select.component';
import { TableIndexComponent } from './components/table-index/table-index.component';
import {AgGridModule} from "ag-grid-angular";
import { TabsTableIndexComponent } from './components/tabs-table-index/tabs-table-index.component';
import {IMaskModule} from "angular-imask";
import {TableIndexService} from "./components/table-index/table-index.service";
import { ButtonBackComponent } from './components/button-back/button-back.component';
import {RouterLink} from "@angular/router";
import { ButtonActionComponent } from './components/button-action/button-action.component';

const FTR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
];
const FTR_COMPONENTS = [
  ButtonTextComponent,
  ButtonIconComponent,
  ButtonBackComponent,
  ButtonActionComponent,
  InputTextComponent,
  ChangePageComponent,
  LoadingComponent,
  CalendarLabelComponent,
  InputSearchComponent,
  LayoutDirectionSwitcherComponent,
  SwitcherComponent,
  SelectComponent,
  TableIndexComponent,
  TabsTableIndexComponent,
];
const FTR_SERVICES = [
  ChangePageService,
  LoadingService,
  TableIndexService,
];

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbDatepickerModule,
  NbInputModule,
  NbCardModule,
  NbAlertModule,
  NbTooltipModule,
  NbTabsetModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
    imports: [CommonModule, ...NB_MODULES, ...FTR_MODULES,
        AgGridModule.withComponents([]), IMaskModule, RouterLink,],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...FTR_MODULES, ...FTR_COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, ...FTR_COMPONENTS, ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
        ...FTR_SERVICES,
      ],
    };
  }
}
