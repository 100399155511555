import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ftrConfig} from "../../../globalConfig";

@Component({
  selector: 'button-text',
  templateUrl: './button-text.component.html',
  styleUrls: ['./button-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonTextComponent implements OnInit {
  shapes: string = ftrConfig.butonText.shape;
  size: string = ftrConfig.butonText.size;
  @Input() buttonText: any;
  constructor(
    private  router: Router,
  ) { }

  ngOnInit() {
    console.log(this.buttonText);
  }
  navegar(url) {
    this.router.navigate([url]);
    console.log('vamos a enviar aqui', url);
  }
}
