<label *ngIf="label != null">{{label}} <span *ngIf="required" style="color: #ca2a40;font-size: 1rem;font-weight: bold;"> &nbsp;*</span></label>
<ng-select
  [(ngModel)]="value"
  [ngModelOptions]="{standalone: true}"
  [multiple]="multiple"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  (change)="valueReturn($event)"
  appendTo="body">
  <ng-option *ngFor="let data of datas" [value]="data.key">{{data.value}}</ng-option>
</ng-select>

