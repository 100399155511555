import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
  OnChanges, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter,
  SimpleChanges, HostListener } from '@angular/core';
import {TableIndexService} from "./table-index.service";
import {LoadingService} from "../loading/loading.service";
import {ChangePageService} from "../change-page/change-page.service";
import {GridOptions} from "ag-grid-community";
import {DatosGenericosService} from "../../../@core/mock/datosGenericos/datosGenericos.service";

@Component({
  selector: 'table-index',
  templateUrl: './table-index.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableIndexComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  startDate: string = '';
  endDate: string = '';
  dataToSearch: string = '';
  paramsFind: any[] = [];
  params: any[] = [];
  columnDefs: any;
  dataFillTable: any = [];
  rowData: any;
  showTable: boolean = false;
  @Input() tableData: any;
  @Input() heightDiv: any;
  @Input() borderCard: boolean = true;
  @Input() loadTable: number = 0;
  @Output() optionsTableClicked = new EventEmitter<any>();
  @Output() valueEditData = new EventEmitter<any>();
  @Output() cellClicked = new EventEmitter<any>();
  gridOptions: GridOptions;
  private gridApi;
  private gridColumnApi;
  private components;
  private localeText;
  page: string = 'Página';
  more: string = 'Más';
  to: string = 'a';
  of: string = 'de';
  next: string = 'Siguiente';
  last: string = 'Último';
  first: string = 'Primero';
  previous: string = 'Anterior';
  loadingOoo: string = 'Cargando ...';
  noRowsToShow: string = 'Sin información';
  lastFilterselected: number = -1;
  heightTable: any;
  showTwoColumns: boolean = false;
  showOneColumn: boolean = false;
  showTwoRows: boolean = false;
  selectedRows: any[];
  showAlertMessage: boolean = false;
  messTextAlert: string = '';
  messTypeAlert: string = 'primary';
  typeSelection: string = 'single';
  showCheckButton: boolean = false;
  slideConfig = {'slidesToShow': 4, 'slidesToScroll': 4};
  constructor(
    private tableIndexService: TableIndexService,
    private loading: LoadingService,
    private changePage: ChangePageService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private generalData: DatosGenericosService,
  ) {
    this.gridOptions = <GridOptions>{
      pagination: true,
      headerHeight: 40.58,
      floatingFiltersHeight: 35,
      rowHeight: 40.58,
      defaultColDef: {}  // Inicializa defaultColDef como un objeto vacío
    };
    this.gridOptions.defaultColDef.resizable = true;
    this.gridOptions.defaultColDef.sortable = true;
    this.components = {numericCellEditor: getNumericCellEditor()};
    this.tableIndexService.observerShowAlert.subscribe(
      (res: { message: string, type: string, reload: boolean }) => {
        this.showAlert(res.message, res.type);
        if (res.reload) {
          this.reloadData();
        }
      });
    this.localeText = {
      page: this.page,
      more: this.more,
      to: this.to,
      of: this.of,
      next: this.next,
      last: this.last,
      first: this.first,
      previous: this.previous,
      loadingOoo: this.loadingOoo,
      noRowsToShow: this.noRowsToShow,
    };
    this.tableIndexService.reziseColumns.subscribe(res => {
      setTimeout(() => {
        this.resizingColumns();
      }, 200);
    });
    this.generalData.reloadTable.subscribe(() => {
      this.reloadData();
    });
  }
  ngOnInit() {
    this.changePage.showChangePage.next(false);
    if (this.tableData.filtersParamns) {
      if (this.tableData.filtersParamns.length < 4) {
        this.showTwoColumns = true;
        this.heightTable = 'calc(' + this.heightDiv + ' - 90px)';
      } else {
        this.showTwoRows = true;
        this.heightTable = 'calc(' + this.heightDiv + ' - 150px)';
      }
    } else {
      this.showOneColumn = true;
      this.heightTable = 'calc(' + this.heightDiv + ' - 90px)';
    }
    if (this.tableData.calendarItem) {
      this.startDate = this.tableData.calendarItem.startDate;
      this.endDate = this.tableData.calendarItem.endDate;
    }
    this.columnDefs = this.tableData.columnDefs;
    this.gridOptions.columnDefs = this.tableData.columnDefs;
    for (let i = 0; i < this.tableData.optionsTable.length; i++) {
      if (this.tableData.optionsTable[i].select === 'multiple') {
        this.typeSelection = 'multiple';
        this.showCheckButton = true;
      }
    }
    this.columnDefs[0].checkboxSelection = this.showCheckButton;
    this.gridOptions.rowSelection = this.typeSelection;
    this.showTable = true;
  }
  ngAfterViewInit() {
    this.iniSlickJs();
  }
  private iniSlickJs() {
    const htmlScriptElement = document.createElement('script');
    htmlScriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js';
    this.elementRef.nativeElement.appendChild(htmlScriptElement);
  }
  ngOnDestroy() {
    this.changePage.showChangePage.next(true);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadTable && changes.loadTable.previousValue != changes.loadTable.currentValue) {
      this.reloadData();
    }
  }
  onCellEditingStopped(event) {
    this.valueEditData.emit(event);
  }
  buttonClicked(value) {
    this.selectedRows = this.gridApi.getSelectedRows();
    const data = {'btn': value, 'rows': this.selectedRows};
    console.log(value);
    if (value.select !== 'null') {
      if (value.select === 'single') {
        if (this.selectedRows.length === 1) {
          this.optionsTableClicked.emit(data);
          setTimeout(() => {
            //this.gridOptions.api.deselectAll();
          }, 1000);
        } else if (this.selectedRows.length < 1) {
          this.showAlert('Se requiere que seleccione un registro', 'warning');
        } else {
          this.showAlert('Solo permite la selección de un registro', 'warning');
        }
      }
      if (value.select === 'multiple') {
        if (this.selectedRows.length < 1) {
          this.showAlert('Se requiere que seleccione un registro', 'warning');
        } else {
          this.optionsTableClicked.emit(data);
          setTimeout(() => {
            this.gridOptions.api.deselectAll();
          }, 1000);
        }
      }
    } else {
      this.optionsTableClicked.emit(data);
      setTimeout(() => {
        this.gridOptions.api.deselectAll();
      }, 1000);
    }
  }
  showAlert(message, type) {
    this.messTextAlert = '';
    this.messTypeAlert = '';
    this.messTextAlert = message;
    this.showAlertMessage = true;
    this.messTypeAlert = type;
    setTimeout(() => {
      this.closeAlert();
    }, 3000);
  }
  closeAlert() {
    this.showAlertMessage = false;
    this.cdr.detectChanges();
  }
  makeProcess(value) {
    if (value) this.reloadData();
  }
  dataSearch(value) {
    this.dataToSearch = value;
    this.gridOptions.api.setQuickFilter(value);
  }
  onGridReady(params) {
    const DOMAIN = this.tableData.dataAPIS.DOMAIN;
    const endpoint = this.tableData.dataAPIS.endPoint;
    this.setTableParams();
    if (this.tableData.filtersParamns) {
      this.tableData.filtersParamns.forEach(val => {
        if (val.selected) {
          this.params.push({param: val.paramn, val: val.value});
        }
      });
    }
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.tableIndexService.dataTableIndex(DOMAIN, endpoint, this.params).subscribe(res => {
      const body: any = res;
      if (body.length !== 0) {
        if (this.gridOptions.api) {
          this.dataFillTable = body;
          this.gridOptions.api.setRowData(this.dataFillTable);
          setTimeout(() => {
            this.resizingColumns();
            this.loading.showLoading.next(false);
          }, 300);
        }
      } else {
        if (this.gridOptions.api) {
          this.gridOptions.api.setRowData([]);
          this.loading.showLoading.next(false);
        }
      }
    }, err => {
      this.gridOptions.api.setRowData([]);
      this.loading.showLoading.next(false);
      console.log('error', err);
    });
  }
  onCellClicked(event) {
    this.cellClicked.emit(event);
  }
  resizingColumns() {
    if (this.gridApi) {
      if (this.tableData.autoSizeColumns) {
        this.gridOptions.defaultColDef = {resizable: true};
        const allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach((column: any) => {
          allColumnIds.push(column.colId);
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    }
  }
  startDateChange(value) {
    this.startDate = value;
    this.cdr.detectChanges();
  }
  endDateChange(value) {
    this.endDate = value;
    this.cdr.detectChanges();
  }
  reloadData() {
    this.dataFillTable = [];
    this.loading.showLoading.next(true);
    const DOMAIN = this.tableData.dataAPIS.DOMAIN;
    const endpoint = this.tableData.dataAPIS.endPoint;
    this.setTableParams();
    if (this.tableData.filtersParamns) {
      this.tableData.filtersParamns.forEach(val => {
        if (val.selected) {
          this.params.push({param: val.paramn, val: true});
        }
      });
    }
    this.tableIndexService.dataTableIndex(DOMAIN, endpoint, this.params).subscribe(res => {
      const body: any = res;
      if (body.length !== 0) {
        if (this.gridOptions.api) {
          this.dataFillTable = body;
          this.gridOptions.api.setRowData(this.dataFillTable);
          this.loading.showLoading.next(false);
        }
      } else {
        this.gridOptions.api.setRowData([]);
        this.loading.showLoading.next(false);
      }
    }, err => {
      this.gridOptions.api.setRowData([]);
      this.loading.showLoading.next(false);
      console.log('error', err);
    });
    setTimeout(() => {
      this.resizingColumns();
    }, 300);
  }
  addParams(option, e) {
    if (this.lastFilterselected !== e) {
      this.lastFilterselected = e;
      this.setTableParams();
      this.tableData.filtersParamns.forEach(val => {
        val.selected = false;
      });
      this.tableData.filtersParamns[e].selected = true;
      this.params.push({param: option.paramn, val: option.value});
    } else {
      this.tableData.filtersParamns[e].selected = false;
      this.setTableParams();
      this.lastFilterselected = -1;
    }
    this.reloadData();
  }
  setTableParams() {
    this.params = [];
    if (this.tableData.calendarItem) {
      const aS = this.startDate.split('-');
      const nDS = new Date(parseInt(aS[0], 10), (parseInt(aS[1], 10) - 1), parseInt(aS[2], 10)).getTime();
      const aE = this.endDate.split('-');
      const nDE = new Date(parseInt(aE[0], 10), (parseInt(aE[1], 10) - 1), (parseInt(aE[2], 10) + 1)).getTime();
      this.params.push({param: 'di', val: nDS});
      this.params.push({param: 'de', val: nDE});
    }
    if (this.tableData.searchData) {
      this.params.push({param: 'sd', val: this.dataToSearch});
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.gridApi) {
      setTimeout(() => {
        this.resizingColumns();
      }, 200);
    }
  }
}
function getNumericCellEditor() {
  function filterFloat(evt, input) {
    const key = evt.keyCode;
    const chark = String.fromCharCode(key);
    const tempValue = input.value + chark;
    if (key >= 48 && key <= 57) {
      if (filter(tempValue) === false) {
        return false;
      } else {
        return true;
      }
    } else {
      if (key === 8 || key === 13 || key === 0) {
        return true;
      } else if (key === 46) {
        if (filter(tempValue) === false) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  function filter(__val__) {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return true;
    } else {
      return false;
    }
  }

  function isCharNumeric(charStr) {
    return !!/^(\d{1}\.)?(\d+\.?)+(,\d{2})?$/.test(charStr);
  }

  function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
  }

  function getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  function NumericCellEditor() {
  }

  NumericCellEditor.prototype.init = function (params) {
    this.focusAfterAttached = params.cellStartedEdit;
    this.eInput = document.createElement('input');
    this.eInput.style.width = '100%';
    this.eInput.style.height = '100%';
    this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
    const that = this;
    this.eInput.addEventListener('keypress', function (event) {

      if (!filterFloat(event, this)) {
        that.eInput.focus();
        if (event.preventDefault) event.preventDefault();
      }
    });
  };
  NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
  };
  NumericCellEditor.prototype.afterGuiAttached = function () {
    if (this.focusAfterAttached) {
      this.eInput.focus();
      this.eInput.select();
    }
  };
  NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
  };
  NumericCellEditor.prototype.isCancelAfterEnd = function () {
  };
  NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
  };
  NumericCellEditor.prototype.focusIn = function () {
    const eInput = this.getGui();
    eInput.focus();
    eInput.select();
    // console.log('NumericCellEditor.focusIn()');
  };
  NumericCellEditor.prototype.focusOut = function () {
    // console.log('NumericCellEditor.focusOut()');
  };
  return NumericCellEditor;
}
