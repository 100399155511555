<div class="header-container" style="height: 3em;">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="assets/images/corbata1.png"></a>
  </div>
  <div style="left: 135px;position: relative;font-weight: bold;font-size: 20px;color: #FFFFFF;">
    {{user?.company}}
  </div>
</div>

<div class="header-container" style="background-color: #4395d1;
    border-radius: 2rem;height: 3em;">
  <nb-actions size="small">
     <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
