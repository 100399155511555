import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ChangePageService} from './change-page.service';

@Component({
  selector: 'change-page',
  templateUrl: './change-page.component.html',
  styleUrls: ['./change-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePageComponent implements OnInit {
  showchangepage: boolean = true;
  constructor(
      private ChangePage: ChangePageService,
  ) {
    this.ChangePage.showChangePage.subscribe((res: boolean) => {
      this.showchangepage = res;
    });
  }

  ngOnInit() {
  }

}
