import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NbDateService} from "@nebular/theme";

@Component({
  selector: 'calendar-label',
  templateUrl: './calendar-label.component.html',
  styleUrls: ['./calendar-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarLabelComponent implements OnInit {
  @Input() calendarData: any;
  @Output() calendarButtonClick = new EventEmitter<any>();
  @Output() startDateChange = new EventEmitter<any>();
  @Output() endDateChange = new EventEmitter<any>();
  formpickerstart: Date;
  formpickerend: Date;
  labelpickerstart: string;
  labelpickerend: string;
  constructor(
    protected  dateService: NbDateService<Date>,
  ) {
  }

  ngOnInit() {
    const startDate = this.calendarData.startDate;
    const endDate = this.calendarData.endDate;
    //console.log('ngon init calendario', startDate, endDate);
    const [year1, month1, day1] = startDate.split('-');
    const [year2, month2, day2] = endDate.split('-');
    this.labelpickerstart = day1 + '-' + month1 + '-' + year1;
    this.labelpickerend = day2 + '-' + month2 + '-' + year2;
    this.formpickerstart = new Date(year1, (month1 - 1), day1);
    this.formpickerend = new Date(year2, (month2 - 1), day2);
  }
  selectDate() {
    this.calendarButtonClick.emit(true);
  }
  startDate(event) {
    if (event < this.formpickerend) {
      const year = (event.getFullYear()).toString();
      let month = (event.getMonth() + 1).toString(),
        day = (event.getDate()).toString();
      if (month.length < 2) { month = '0' + month; }
      if (day.length < 2) { day = '0' + day; }
      const date = year + '-' + month + '-' + day;
      this.startDateChange.emit(date);
    } else {
      setTimeout(() => {
        this.formpickerstart = this.formpickerend;
      }, 300);
    }
  }
  endDate(event) {
    if (event > this.formpickerstart) {
      const year = (event.getFullYear()).toString();
      let month = (event.getMonth() + 1).toString(),
        day = (event.getDate()).toString();
      if (month.length < 2) { month = '0' + month; }
      if (day.length < 2) { day = '0' + day; }
      const date = year + '-' + month + '-' + day;
      this.endDateChange.emit(date);
    } else {
      setTimeout(() => {
        this.formpickerend = this.formpickerstart;
      }, 300);
    }
  }
}
