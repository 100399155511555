import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent implements OnInit {
  showLoading: boolean = true;
  constructor(
    private Loading: LoadingService,
  ) {
    this.Loading.showLoading.subscribe((res: boolean) => {
      this.showLoading = res;
    });
  }

  ngOnInit() {

  }

}
