import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ChangePageService {
    showChangePage = new Subject();
    constructor() {

    }
}
