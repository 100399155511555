import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputSearchComponent implements OnInit {
  @Input() inputSearchData: any;
  @Output() dataSearch = new EventEmitter<any>();
  valueSearch: string = '';
  constructor() { }
  ngOnInit() {
  }
  findData() {
    this.dataSearch.emit(this.valueSearch);
  }
  clearData() {
    this.valueSearch = '';
    this.dataSearch.emit('');
  }
}
