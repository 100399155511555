import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable()
export class DocumentService {

    private dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
    private uuid = this.dataUser.uuidCompany;
    private currentUser = this.dataUser.name;
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Method': 'POST,GET,OPTIONS',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
        })
    };

    constructor(
        private http: HttpClient,
    ) {
    }

    getAutomaticTag() {
        let shareGet;
        let url = `${environment.api.templateApis}tags_automatic`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    createDocument(data: any): Observable<any> {
        //console.log('server', data);

        let formData = new FormData();

        formData.append("uuid_company", data.uuid_company);
        formData.append("currentUserName", data.currentUserName);
        formData.append("file", data.file);
        formData.append("templateUUID", data.templateUUID);
        formData.append('tags', data.tags);
        formData.append('alias', data.alias);
        //console.log('antes de ir', formData);

        let url = `${environment.api.templateApis}company/${data.uuid_company}/template`;
        return this.http.post(url, formData, this.httpOptions);
    }

    updateDocument(data: any): Observable<any> {
        //console.log('server', data);

        let formData = new FormData();

        formData.append("file", data.file);
        formData.append("template", data.template);
        formData.append("uuid", data.uuid);
        formData.append("currentUserName", data.currentUserName);

        //console.log('antes de ir', formData);

        let url = `${environment.api.templateApis}company/${data.uuid_company}/update-template`;
        return this.http.post(url, formData, this.httpOptions);
    }

    exportFileConfig(uuid) {
        let url = `${environment.api.dataApis}/documento/download-config/${uuid}`;
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Desactive el bloqueador de elementos emergentes y vuelva a intentarlo.');
        }
    }

    delete(uuid): Observable<any> {
        let url = `${environment.api.templateApis}${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }

    getDocuments() {
        let shareGet;
        let url = `${environment.api.templateApis}company/documents/configuration`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    saveEvidence(data: any, uuid: any): Observable<any> {
        let formData = new FormData();
        formData.append("file", data);
        let url = `${environment.api.templateApis}evidence/${uuid}`;
        return this.http.post(url, formData, this.httpOptions);
    }
}
