import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class EmpleadoService {
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Method': 'POST,GET,OPTIONS',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
            'Accept': 'application/octet-stream'
        })
    };
    private dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
    private uuid = this.dataUser.uuidCompany;
    private currentUser = this.dataUser.name;

    constructor(
        private http: HttpClient
    ) {
    }

    createEmployee(data: any): Observable<any> {
        let url = `${environment.api.adminApis}${this.uuid}/employees`;
        return this.http.post(url, data, this.httpOptions);
    }

    getEmployee() {
        let shareGet;
        let url = `${environment.api.adminApis}${this.uuid}/employees`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    getEmployeeUUID(uuid) {
        let shareGet;
        let url = `${environment.api.adminApis}${this.uuid}/employees/${uuid}`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    getDocumentsEmployees(uuid) {
        let shareGet;
        let url = `${environment.api.adminApis}employees/documents/${uuid}`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    updateEmployee(data, register): Observable<any> {
        let url = `${environment.api.adminApis}${this.uuid}/employees/${register}`;
        return this.http.put(url, data);
    }

    deleteEmployee(uuid): Observable<any> {
        let url = `${environment.api.adminApis}${this.uuid}/employees/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }

    deleteEmployeeDelete(uuid): Observable<any> {
        let url = `${environment.api.adminApis}${this.uuid}/employees-delete/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }

    reactivateEmployee(uuid): Observable<any> {
        let url = `${environment.api.adminApis}${this.uuid}/employees-reactivate/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }

    generateDocumentEmployee(data: any, uuid_Document, uuid_employee): Observable<any> {
        /*let url = `${environment.api.dataApis}/template/documents/${uuid_Document}/employees/${uuid_employee}/generate_document`;
        return this.http.post(url, data, this.httpOptions);*/

        let url: string = `${environment.api.dataApis}/template/documents/${uuid_Document}/employees/${uuid_employee}/generate_document`;
        return this.http.post(url, data, {
            responseType: 'arraybuffer', headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Request-Method': 'POST,GET,OPTIONS',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
                'Accept': 'application/octet-stream'
            })
        });
    }

    exportDocumentEmployee(data: any): Observable<any> {
        /*let url = `${environment.api.dataApis}/template/documents/${uuid_Document}/employees/${uuid_employee}/generate_document`;
        return this.http.post(url, data, this.httpOptions);*/

        let url: string = `${environment.api.adminApis}documents/download`;
        return this.http.post(url, data, {
            responseType: 'arraybuffer', headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Request-Method': 'POST,GET,OPTIONS',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
                'Accept': 'application/octet-stream'
            })
        });
    }

    exportFileConfig(uuid) {
        let url = `${environment.api.templateApis}${uuid}/documents`;
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Desactive el bloqueador de elementos emergentes y vuelva a intentarlo.');
        }
    }
}
