import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authservice: AuthService,
    private route: Router,
  ) { }
  canActivate () {
    if (this.authservice.isLoggedIn()) {
      this.route.navigate(['/pages']);
    }
    return !this.authservice.isLoggedIn();
  }
}
