import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter,
  Input, OnInit, Output, ViewEncapsulation,} from '@angular/core';
import {ChangePageService} from "../change-page/change-page.service";
import {TableIndexService} from "../table-index/table-index.service";

@Component({
  selector: 'tabs-table-index',
  templateUrl: './tabs-table-index.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tabs-table-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsTableIndexComponent implements OnInit, AfterViewInit {
  @Input() tableDataArray: any[];
  @Input() heightDiv: any;
  @Output() optionsTableClickedTab = new EventEmitter<any>();
  @Output() valueEditDataTab = new EventEmitter<any>();
  @Output() cellClickedTab = new EventEmitter<any>();
  reziceReady: boolean = false;
  constructor(
    private changePage: ChangePageService,
    private tablelist: TableIndexService,
  ) {
  }
  ngOnInit() {
  }
  optionsTableClicked(value, tab) {
    const data = {
      'values': value,
      'tab': tab,
    };
    this.optionsTableClickedTab.emit(data);
  }
  valueEditData(value, tab) {
    const data = {
      'values': value,
      'tab': tab,
    };
    this.valueEditDataTab.emit(data);
  }
  cellClicked(value, tab) {
    const data = {
      'values': value,
      'tab': tab,
    };
    this.cellClickedTab.emit(data);
  }
  fixSize() {
    if (this.reziceReady) {
      this.tablelist.reziseColumns.next(true);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.reziceReady = true;
    }, 200);
  }
}
