import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RandomGuard implements CanActivate, CanLoad {
  constructor(
    private authservice: AuthService,
    private router: Router,
  ) {}
  canActivate() {
    return this.canLoad();
  }
  canLoad() {
    if (!this.authservice.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    return this.authservice.isLoggedIn();
  }
}
