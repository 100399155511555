import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class DatosGenericosService {

    reloadTable = new Subject();

    httpOption = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Request-Method':'POST,GET,OPTIONS',
            'Access-Control-Request-Headers':'Content-Type, Authorization'
        })
    };
    constructor(private http: HttpClient){}

    getDocumentsType(uuidCompany, status) {
        let shareGet;
        let url = `${environment.api.dataApis}/template/company/${uuidCompany}/documents`;
        shareGet = this.http.get(url);
        return shareGet;
    }
    getDocumentsTypeStatus(uuidCompany, status) {
        let shareGet;
        let url = `${environment.api.dataApis}/template/template/company/${uuidCompany}?documentsRegistered=${status}`;
        shareGet = this.http.get(url);
        return shareGet;
    }
    getPermissionUser() {
        let shareGet;
        let url = `${environment.api.dataApis}/users/permissions`;
        shareGet = this.http.get(url);
        return shareGet;
    }
}
