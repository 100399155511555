import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable()
export class StandarService {
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Method': 'POST,GET,PUT,DELETE,OPTIONS',
            'Access-Control-Request-Headers': 'Content-Type, Authorization'
        })
    };
    private dataUser = null;
    private uuid = null;
    private currentUser = null;

    editar = new Subject();

    constructor(
        private http: HttpClient
    ) {
        this.dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
        this.uuid = this.dataUser.uuidCompany;
        this.currentUser = this.dataUser.name;
    }

    create(data: any): Observable<any> {
        let url = `${environment.api.standardApis}standard`;
        return this.http.post(url, data, this.httpOptions);
    }

    update(data, uuid): Observable<any> {
        //console.log(data, uuid);
        let url = `${environment.api.standardApis}standard/update/${uuid}`;
        return this.http.put(url, data);
    }

    getCompany() {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/company`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    all() {
        let shareGet;
        let url = `${environment.api.standardApis}standard`;
        shareGet = this.http.get(url);
        return shareGet;
    }

    delete(uuid): Observable<any> {
        let url = `${environment.api.standardApis}standard/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }

    saveEvidence(data: any, uuid: any): Observable<any> {
        //console.log('server', data);
        let formData = new FormData();
        formData.append("file", data);
        //console.log('antes de ir', formData);
        let url = `${environment.api.standardApis}standard/evidence/${uuid}?currentUser=${this.currentUser}`;
        return this.http.post(url, formData, this.httpOptions);
    }
}
