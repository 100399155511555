import {Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonIconComponent implements OnInit {

  @Input() buttonIcon: any;
  @Output() buttonClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  processOption(value) {
    this.buttonClick.emit(value);
  }
}

