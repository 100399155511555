import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';

@Injectable()
export class TableIndexService {
  observerShowAlert = new Subject();
  reziseColumns = new Subject();
  constructor(
    private http: HttpClient,
  ) {
  }
  dataTableIndex(DOMAIN: string, endpoint: string, params?: any[]) {
    let shareGet;
    let complement = '';
    if (params) {
      for (let i = 0; i < params.length; i++) {
        if (params[i].param == 'sd' && params[i].val != '')
          complement += params[i].param + '=' + params[i].val + '&';
        else if (params[i].param != 'sd')
          complement += params[i].param + '=' + params[i].val + '&';
      }
      complement = complement.substring(0, (complement.length - 1));
    }
    shareGet = this.http.get(DOMAIN + endpoint + (complement != '' ? `?${complement}` : '')
    )
    ;
    return shareGet;
  }
}
