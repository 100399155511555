<nb-card [ngStyle]="{'height': heightDiv}" [ngClass]="{'no-border' : !borderCard}">
  <nb-card-body>
    <div class="row show-grid alertMess" *ngIf="showAlertMessage">
      <nb-alert status="{{messTypeAlert}}" class="barAlertMess">{{messTextAlert}}</nb-alert>
    </div>
    <div *ngIf="showTwoColumns">
      <div class="row show-grid animate__animated animate__bounceIn">
        <div class="col-md-12" style="padding-left: 10px;padding-right: 10px;">
          <div class="row show-grid" style="display: flow-root">
            <button-text *ngIf="tableData.buttonsTable" [buttonText]="tableData.buttonsTable"></button-text>&nbsp;&nbsp;&nbsp;&nbsp;
            <button *ngIf="tableData.reloadButton.show" class="btn btn-outline-primary btn-icon
             animate__animated animate__bounceIn reload"
                    [nbTooltip]="tableData.reloadButton.title" (click)="reloadData()" type="button">
              <i class="fas fa-sync-alt"></i></button>
            <button-icon *ngIf="tableData.optionsTable" [buttonIcon]="tableData.optionsTable"
                         (buttonClick)="buttonClicked($event)"></button-icon>&nbsp;&nbsp;
            <calendar-label *ngIf="tableData.calendarItem" [calendarData]="tableData.calendarItem"
                            (calendarButtonClick)="makeProcess($event)"
                            (startDateChange)="startDateChange($event)"
                            (endDateChange)="endDateChange($event)"></calendar-label>&nbsp;&nbsp;
            <input-search *ngIf="tableData.searchData" [inputSearchData]="tableData.searchData"
                          (dataSearch)="dataSearch($event)"></input-search>
          </div>
        </div>
        <div class="col-md-12" style="padding-left: 0;display: flow-root;text-align: right; padding-right: 0;">
          <div *ngFor="let button of tableData.filtersParamns; let e = index" class="card filter-paramns"
               style="width: 32%;" (click)="addParams(button , e)"
               [ngClass]="{'filter-selected': button.selected}">
            <div class="card-header" [nbTooltip]="button.title">{{button.title}}</div>
            <div class="card-body" [nbTooltip]="button.value">{{button.value}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showOneColumn">
      <div class="row show-grid animate__animated animate__bounceIn">
        <div class="col-md-6">
          <button-text *ngIf="tableData.buttonsTable" [buttonText]="tableData.buttonsTable"></button-text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button style="margin-top: -9px;" *ngIf="tableData.reloadButton.show"
                  class="btn btn-outline-primary btn-icon animate__animated animate__bounceIn
                   reload" [nbTooltip]="tableData.reloadButton.title"
                  (click)="reloadData()" type="button">
            <i class="fas fa-sync-alt"></i></button>
          <button-icon *ngIf="tableData.optionsTable" [buttonIcon]="tableData.optionsTable"
                       (buttonClick)="buttonClicked($event)"></button-icon>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <calendar-label *ngIf="tableData.calendarItem" [calendarData]="tableData.calendarItem"
                          (calendarButtonClick)="makeProcess($event)"
                          (startDateChange)="startDateChange($event)"
                          (endDateChange)="endDateChange($event)"></calendar-label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input-search *ngIf="tableData.searchData" [inputSearchData]="tableData.searchData"
                        (dataSearch)="dataSearch($event)"></input-search>
        </div>
      </div>
    </div>
    <div *ngIf="showTwoRows">
      <div class="row show-grid animate__animated animate__bounceIn" *ngIf="tableData.filtersParamns" style="display: flow-root;text-align: center;">

        <!--<ngx-slick-carousel class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig">-->
        <div *ngFor="let button of tableData.filtersParamns; let e = index" class="card filter-paramns slide"
             style="width: 16%;" (click)="addParams(button , e)"
             [ngClass]="{'filter-selected': button.selected}">
          <div class="card-header" [nbTooltip]="button.title">{{button.title}}</div>
          <div class="card-body" [nbTooltip]="button.value">{{button.value}}</div>
        </div>
        <!--</ngx-slick-carousel>-->
      </div>
      <div class="row show-grid animate__animated animate__bounceIn">
        <div class="col-md-6">
          <button-text *ngIf="tableData.buttonsTable" [buttonText]="tableData.buttonsTable"></button-text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button style="margin-top: -9px;" *ngIf="tableData.reloadButton.show"
                  class="btn btn-outline-primary btn-icon
             animate__animated animate__bounceIn reload" [nbTooltip]="tableData.reloadButton.title"
                  (click)="reloadData()" type="button">
            <i class="fas fa-sync-alt"></i></button>
          <button-icon *ngIf="tableData.optionsTable" [buttonIcon]="tableData.optionsTable"
                       (buttonClick)="buttonClicked($event)"></button-icon>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <calendar-label *ngIf="tableData.calendarItem" [calendarData]="tableData.calendarItem"
                          (calendarButtonClick)="makeProcess($event)"
                          (startDateChange)="startDateChange($event)"
                          (endDateChange)="endDateChange($event)"></calendar-label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input-search *ngIf="tableData.searchData" [inputSearchData]="tableData.searchData"
                        (dataSearch)="dataSearch($event)"></input-search>
        </div>
      </div>
    </div>
    <ag-grid-angular
      *ngIf="showTable"
      [style.height]="heightTable"
      style="width:100%"
      class="ag-theme-balham animate__animated animate__backInRight"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (cellClicked)="onCellClicked($event)"
      [components]="components"
      [suppressAggFuncInHeader]="true"
      [localeText]="localeText"
      [pagination]="true"
      [animateRows]="true"
      [paginationAutoPageSize]="true"
    >
    </ag-grid-angular>
  </nb-card-body>
</nb-card>

