import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges,
  OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {SelectService} from "./select.service";

@Component({
  selector: 'inteligent-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() inputSearchData: any;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() label: string = null;
  @Input() urlSource: string = '';
  @Input() dataSource: { key: any, value: any } [] = null;
  @Input() type: 'data' | 'url' = 'url';
  // para que sea un input ouput bidireccional se deben de llamar igual solo que al outPut se le agrega el Change
  @Input() defaultValue: any = null;
  @Output() defaultValueChange = new EventEmitter<any>();
  value: any = null;
  datas: any[] = [];
  readonly DOMAIN = environment.api.utcvApis;
  private $subcription: Subscription;
  constructor(
    private cdr: ChangeDetectorRef,
    private service: SelectService,
  ) {
  }
  ngOnInit() {
    if (this.type == 'url') {
      this.$subcription = this.service.get(`${this.DOMAIN}/${this.urlSource}`).subscribe(
        (res: any) => {
          if (res) {
            this.datas = res;
            setTimeout(() => {
              if (this.defaultValue != null) {
                this.value = this.defaultValue;
              }
            });
          }
        },
        error1 => {
        });

    } else if (this.type == 'data') {
      this.datas = this.dataSource;
      setTimeout(() => {
        if (this.defaultValue != null) {
          this.value = this.defaultValue;
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue){
      if (changes.defaultValue.previousValue != changes.defaultValue.currentValue) {
        this.value = changes.defaultValue.currentValue;
      }
    }
    if (changes.dataSource){
      if (changes.dataSource.previousValue != changes.dataSource.currentValue) {
        this.datas = changes.dataSource.currentValue;
      }
    }
  }
  ngAfterViewInit(): void {
    if (this.defaultValue !== '') {
      this.value = this.defaultValue;
      this.cdr.detectChanges();
    }
  }
  valueReturn(element) {
    this.defaultValueChange.emit(this.value);
  }
}
