export const ftrConfig = {
  butonTable: {
    size: 'tiny',
    shape: 'rectangle',
  },
  butonIcon: {
    size: 'tiny',
    shape: 'rectangle',
  },
  butonText: {
    size: 'tiny',
    shape: 'rectangle',
  },
  butonCalendar: {
    size: 'tiny',
    shape: 'rectangle',
  },
  butonSearch: {
    size: 'tiny',
    shape: 'rectangle',
  },
  inputText: {
    textAreaRowsValue: 4,
  },
};
