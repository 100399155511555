<div class=" container-login large-header" id="large-header">
  <div class="left-section" style=" background: linear-gradient(to bottom, #154360, #82bfff);">
    <div class="header">
      <div class="text-center animation a1" style="position: relative; top:5%">
        <img src="assets/images/logologin.png"/>
      </div>
      <!--<h4 class="animation a2">Log in for entering your membership dashboard.</h4>-->
    </div>
    <div class="form text-center">
      <form name="form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()" autocomplete="off">
        <!--<input type="email" class="form-field animation a3" placeholder="Username">-->
        <div class="login__row animation a3"
             [ngClass]="{'is-invalid':form.get('email').touched && form.get('email').invalid}">
          <i>
            <svg class="login__icon name svg-icon" viewBox="0 0 20 20">
              <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8"/>
            </svg>
          </i>
          <input type="text" name="email" class="login__input email" formControlName="email"
                 placeholder="Correo electr&oacute;nico" autocomplete="newPassword" value="user@email.com"/>
          <div *ngIf="form.get('email').touched && form.get('email').invalid" class="invalid-feedback">
            <div *ngIf="form.get('email').errors.required">El campo correo electr&oacute;nico es requerido
            </div>
            <div *ngIf="form.get('email').errors.email">El correo electr&oacute;nico debe ser un formato
              valido
            </div>
          </div>
        </div>
        <!--<input type="password" class="form-field animation a4" placeholder="Password">-->
        <div class="login__row animation a4"
             [ngClass]="{'is-invalid':form.get('password').touched && form.get('password').invalid}">
          <i>
            <svg class="login__icon pass svg-icon" viewBox="0 0 20 20">
              <path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0"/>
            </svg>
          </i>
          <input type="password" name="password" class="login__input pass" formControlName="password"
                 placeholder="Contrase&ntilde;a" maxlength="11" value="secret"/>
          <div *ngIf="form.get('password').touched && form.get('password').invalid" class="invalid-feedback">
            <div *ngIf="form.get('password').errors.required">La contrase&ntilde;a es requerida</div>
            <div *ngIf="form.get('password').errors.minlength">Su contrase&ntilde;a no puede ser menor a 6
              caracteres
            </div>
            <div *ngIf="form.get('password').errors.maxlength">Su contrase&ntilde;a no puede ser mayor a 10
              caracteres
            </div>
          </div>
        </div>

        <!-- Error Message Div -->
        <div *ngIf="errorMessage" class="error-message animation a5" style="color: red;">
          {{ errorMessage }}
        </div>

        <br/>
        <div class="animation a5" style="text-align: center;">
          <button *ngIf="!isSubmited" type="submit" tabindex="3" class="login__submit custom-button"
                  [disabled]="!form.valid">Acceder
          </button>
        </div>
        <br>
        <div *ngIf="isSubmited" class="a loading" style="--n: 5;">
          <div class="dot" style="--i: 0;"></div>
          <div class="dot" style="--i: 1;"></div>
          <div class="dot" style="--i: 2;"></div>
          <div class="dot" style="--i: 3;"></div>
          <div class="dot" style="--i: 4;"></div>
        </div>
        <!--<button class="animation a6">LOGIN</button>-->
      </form>
    </div>
  </div>
  <div class="right-section">
    <div style="top: 0; right: 0; left: 0; bottom: 0; background-color: black;"></div>
    <div style="text-align: center;
    color: white;
    position: absolute;
    top: 83%;
    font-size: 2rem;
    /* background-color: #00000075; */
    padding: 3rem;
    border-radius: 50%;
    font-weight: bold;
    ">La cultura de la prevención legal llevada
      a lo digital
    </div>
    <div class="right-section-image"><img style="height: 100%;"
                                          src="https://laboralsoft.com.mx/wp-content/uploads/2022/11/LaboralSoft-3.png">
    </div>
  </div>
</div>
<!--<div id="large-header" class="large-header">
  <div class="demo">
    <div class="login">
      <div class="alert alert-danger" [hidden]="!error">
        {{error}}
      </div>
      <div class="login__check">
        <h3 style="font-weight: 700;color: #f1f5ed;margin-top: 1rem;"></h3>
      </div>
      <div class="text-center" style="position: relative; top:5%">
        <img src="assets/images/logologin.png"/>
      </div>

      <div class="login__form" style="margin-top: -50px!important;">
        <form name="form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()" autocomplete="off" style="position: relative">

          <div class="login__row" [ngClass]="{'is-invalid':form.get('email').touched && form.get('email').invalid}" >
            <i>
              <svg class="login__icon name svg-icon" viewBox="0 0 20 20">
                <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8"/>
              </svg>
            </i>
            <input type="text" name="email" class="login__input email" formControlName="email" placeholder="Correo electr&oacute;nico" autocomplete="newPassword" value="user@email.com"/>
            <div *ngIf="form.get('email').touched && form.get('email').invalid"  class="invalid-feedback">
              <div *ngIf="form.get('email').errors.required">El campo correo electr&oacute;nico es requerido</div>
              <div *ngIf="form.get('email').errors.email">El correo electr&oacute;nico debe ser un formato valido</div>
            </div>
          </div>
          <div class="login__row" [ngClass]="{'is-invalid':form.get('password').touched && form.get('password').invalid}">
            <i>
              <svg class="login__icon pass svg-icon" viewBox="0 0 20 20">
                <path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0"/>
              </svg>
            </i>
            <input type="password" name="password" class="login__input pass"  formControlName="password" placeholder="Contrase&ntilde;a" maxlength="11" value="secret"/>
            <div *ngIf="form.get('password').touched && form.get('password').invalid"  class="invalid-feedback">
              <div *ngIf="form.get('password').errors.required">La contrase&ntilde;a es requerida</div>
              <div *ngIf="form.get('password').errors.minlength">Su contrase&ntilde;a no puede ser menor a 6 caracteres</div>
              <div *ngIf="form.get('password').errors.maxlength">Su contrase&ntilde;a no puede ser mayor a 10 caracteres</div>
            </div>
          </div><br/>
          <div  style="text-align: right;padding-right: 3rem;">
            <button *ngIf="!isSubmited" type="submit" tabindex="3"
                    class="login__submit" [ngClass]=""
                    [disabled]="!form.valid">Acceder
            </button>
          </div><br>
          <div *ngIf="isSubmited" class="a loading" style="--n: 5;">
            <div class="dot" style="--i: 0;"></div>
            <div class="dot" style="--i: 1;"></div>
            <div class="dot" style="--i: 2;"></div>
            <div class="dot" style="--i: 3;"></div>
            <div class="dot" style="--i: 4;"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>-->
