import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class JornadaLaboralService {
    private dataUser = JSON.parse(localStorage.getItem('USER_CURRENT'));
    private uuid = this.dataUser.uuidCompany;
    private currentUser = this.dataUser.name;
    constructor(private http: HttpClient) {
    }

    get(domain: string, endpoint: string, options?: any) {
        return this.http.get(domain + '/' + endpoint, options);
    }

    post(domain: string, endpoint: string, body: any, options?: any) {
        return this.http.post(domain + '/' + endpoint, body, options);
    }

    patch(domain: string, endpoint: string, body: any, options?: any) {
        return this.http.patch(domain + '/' + endpoint, body, options);
    }

    put(domain: string, endpoint: string, body?: any, options?: any) {
        return this.http.put(domain + '/' + endpoint, body, options);
    }

    remove(domain: string, endpoint: string, options?: any) {
        return this.http.delete(domain + '/' + endpoint, options);
    }

    getJornadaLaboral() {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/${this.uuid}/working_hours-active`;
        shareGet = this.http.get(url);
        return shareGet;
    }
    getJornadaLaboralUUID(uuid) {
        let shareGet;
        let url = `${environment.api.CatalogsApis}/${this.uuid}/working_hours/${uuid}`;
        shareGet = this.http.get(url);
        return shareGet;
    }
    deleteJornadaLaboral(uuid): Observable<any> {
        let url = `${environment.api.CatalogsApis}/${this.uuid}/working_hours/${uuid}?currentUser=${this.currentUser}`;
        return this.http.delete(url);
    }
}
