import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputTextComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() inputSearchData: any;
  @Input() class: any = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() label: string = null;
  @Input() prefix: string = null;
  @Input() suffix: string = null;
  @Input() maxDate: string = '';
  @Input() minDate: string = '';
  @Input() maxLength: number = 1000;
  @Input() minLength: number = 0;
  @Input() maxInteger = -1;
  @Input() minInteger = -1;
  @Input() maxDouble = -1;
  @Input() minDouble = -1;
  @Input() fitContent = false;
  @Input() textAlign: 'right' | 'left' | 'center' = 'left';
  @Input() type: 'password' | 'dateinput' | 'onlyUpperCase' | 'nospace' | 'integer' | 'double' | 'alphanumeric' | 'onlyLetters' | 'all' | 'email' = 'all';
  // para que sea un input ouput bidireccional se deben de llamar igual solo que al outPut se le agrega el Change
  @Input() defaultValue: any = null;
  @Output() defaultValueChange = new EventEmitter<any>();
  value: any = null;
  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.defaultValue !== '') {
      this.value = this.defaultValue;
      this.cdr.detectChanges();
    }
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue)
      if (changes.defaultValue.previousValue != changes.defaultValue.currentValue) {
        this.value = changes.defaultValue.currentValue;
      }

    if (changes.textAlign)
      if (changes.textAlign.previousValue != changes.textAlign.currentValue) {
        this.textAlign = changes.textAlign.currentValue;
      }
  }
  valueReturn() {
    if (this.type === 'integer') {
      if (this.maxInteger !== -1) {
        this.value = this.maxInteger;
      }
      if (this.minInteger !== -1) {
        this.value = this.maxInteger;
      }
    }
    if (this.type === 'double') {
      if (this.maxDouble !== -1) {
        this.value = this.maxDouble;
      }
      if (this.minDouble !== -1) {
        this.value = this.minDouble;
      }
    }
    this.defaultValueChange.emit(this.value);
  }

  valueUpperReturn() {
    let valaux = this.value;
    valaux = valaux.toUpperCase();
    this.defaultValueChange.emit(valaux);
  }

  onlyFloat(e, field) {
    const key = e.keyCode ? e.keyCode : e.which;
    // backspace
    if (key === 8) {
      return true;
    }
    // 0-9 a partir del .decimal
    if (field.value === '.') {
      field.value = '0';
      return false;
    }
    if (field.value !== '') {
      if ((field.value.indexOf('.')) > 0) {
        if (key > 47 && key < 58) {
          if (field.value === '') {
            return true;
          }
          const regexp = /[0-9]{2}$/;
          return !(regexp.test(field.value));
        } else {
          return false;
        }
      }
    }
    // 0-9
    if (key > 47 && key < 58) {
      if (field.value === '') {
        return true;
      }
      const regexp = /[0-9]{6}/;
      return !(regexp.test(field.value));
    }
    // .
    if (key === 46) {
      if (field.value === '') {
        return false;
      }
      const regexp = /^[0-9]+$/;
      return regexp.test(field.value);
    }
    // other key
    return false;
  }

  onlyNumeric(evt) {
    let keynum;
    if (window.event) {// IE
      keynum = evt.keyCode;
    } else {
      keynum = evt.which;
    }
    if ((keynum === 8) || (keynum === 37)) {
      return true;
    }
    return keynum > 47 && keynum < 58;
  }

  validateAlphanumeric(element) {
    const regex = new RegExp('^[ a-zA-ZÀ-ÿ0-9ñÑ]+$');
    const key = String.fromCharCode(!element.charCode ? element.which : element.charCode);
    if (!regex.test(key)) {
      return false;
    }
  }
  validateAlphanumericnospace(element) {
    const regex = new RegExp('^[a-zA-ZÀ-ÿ0-9ñÑ]+$');
    const key = String.fromCharCode(!element.charCode ? element.which : element.charCode);
    if (!regex.test(key)) {
      return false;
    }
  }
  validateOnlyLetters(element) {
    // const regex = new RegExp('^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$');
    const regex = new RegExp('^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+$');
    const key = String.fromCharCode(!element.charCode ? element.which : element.charCode);
    if (!regex.test(key)) {
      return false;
    }
  }

}

