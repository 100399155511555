import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {inputNames} from "@angular/cdk/schematics";

@Component({
  selector: 'button-action',
  templateUrl: './button-action.component.html',
  styleUrls: ['./button-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonActionComponent implements OnInit {
  @Input() tipeclass: any;
  @Input() tipeicon: any;
  @Input() tipetitle: any;
  @Input() tipetype: any;
  @Input() tipedisabled: any;
  @Output() buttonClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  processOption(value) {
    this.buttonClick.emit(value);
  }
}
