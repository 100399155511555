import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class UsuariomasterService {
    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Request-Method':'POST,GET,OPTIONS',
            'Access-Control-Request-Headers':'Content-Type, Authorization',
        })
    };
    constructor(
        private http: HttpClient,
    ) { }

    createUserMaster(data:any, company): Observable<any> {
        let url = `${environment.api.dataApis}/users/company/${company}/create_master`;
        return this.http.post(url, data, this.httpOptions);
    }

    createUserCompany(data:any, company): Observable<any> {
        let url = `${environment.api.dataApis}/users/company/${company}/create_user`;
        return this.http.post(url, data, this.httpOptions);
    }
}
