import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {

  constructor() { }

public  onSubmit() {

  }
  handleError(error) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }
}
