import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {LoginComponent} from './auth/containers/login/login.component';
import {AuthGuard} from './auth/guards/auth.guard';
import {RandomGuard} from './auth/guards/random.guard';
import {RecoveryComponent} from "./auth/containers/recovery/recovery.component";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recovery',
    component: RecoveryComponent,
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  {
    path: 'asistencia',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
