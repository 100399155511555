<div class="calendarSection" *ngIf="calendarData.buttonshow">
  <input nbInput placeholder="Fecha inicial" [nbDatepicker]="formpicker" [(ngModel)]="formpickerstart" readonly>
  <nb-datepicker (dateChange)="startDate($event)" format="dd-MM-yyyy" #formpicker></nb-datepicker>
  &nbsp;<span style="font-size:25px;color:#547080;margin-top: 1.2rem;font-weight: bold">-</span>&nbsp;
  <input nbInput placeholder="Fecha final" [nbDatepicker]="formpicker2" [(ngModel)]="formpickerend" readonly>
  <nb-datepicker (dateChange)="endDate($event)" format="dd-MM-yyyy" #formpicker2></nb-datepicker>&nbsp;&nbsp;
  <button class="btn btn-outline-primary btn-icon"  (click)="selectDate()" type="button">
    <i class="far fa-calendar-alt"></i></button>
</div>
<div class="calendarSection" *ngIf="!calendarData.buttonshow">
  <label> {{labelpickerstart}}</label>
  &nbsp;<span style="margin-top:-2px;font-size:20px;color:#547080;font-weight: bold">-</span>&nbsp;
  <label>{{labelpickerend}}</label>
</div>
