import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonBackComponent implements OnInit {
  @Input() routeSend: any;
  constructor(
  ) { }
  ngOnInit() {
  }
}
